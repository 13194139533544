import './form.scss';
import moment from 'moment';
import Litepicker from 'litepicker';

class Form {
    constructor ($element) {
        this.$form = $element;
        this.$formBirthDate = this.$form.querySelector('[data-birth-date="content"]');
        this.$formBirthDateInput = this.$form.querySelector('[data-birth-date="input"]');
        this.$formSubmitButton = this.$form.querySelector('[data-form="submit"]');
        this.$fields = this.$form.querySelectorAll('[required]');
        this.$notRequiredFields = this.$form.querySelectorAll('[data-form="not-required"]');
        this.$passwordTrigger = this.$form.querySelectorAll('[data-show-password="trigger"]');
        this.$passwordContent = this.$form.querySelectorAll('[data-show-password="content"]');
        // this.$ibanInput = this.$form.querySelector('[data-iban="input"]');
        this.$emailInput = this.$form.querySelector('[data-email="input"]');
        this.$postcodeInput = this.$form.querySelector('[data-postcode="input"]');
        this.$paymentIbanSepa = this.$form.querySelector('[data-payment-iban="sepa"]');
        this.$paymentCreditcard = this.$form.querySelector('[data-payment-type="creditcard"]');
        this.$paymentPayPal = this.$form.querySelector('[data-payment-type="paypal"]');
        this.$firstLoad = true;
        this.litepicker = null;

        if (this.$formSubmitButton !== null) {
            this.isChannelizerForm = this.$formSubmitButton.hasAttribute('data-channelizer');
        }
    }

    initialize () {
        this.showPassword();
        this.initBirthdate();
        this.checkNonRequiredFields();
        this.checkRequiredFields();
        this.setEvents();
    }

    initBirthdate () {
        if (this.$formBirthDate) {
            const maxDate = moment().subtract(18, 'years');
            this.litepicker = new Litepicker({
                element: this.$formBirthDate,
                format: 'DD.MM.YYYY',
                lang: 'de-DE',
                autoRefresh: true,
                mobileFriendly: false,
                firstDay: 0,
                maxDate: maxDate,
                singleMode: true,
                dropdowns: {
                    minYear: 1920,
                    years: true,
                    months: true
                },
                onHide: () => {
                    if (this.$formBirthDate.value.length !== 0) {
                        this.$formBirthDate.parentElement.classList.add('is--filled');
                        this.checkRequiredFields();
                        this.checkNonRequiredFields();
                    }
                }
            });
        }
    }

    showPassword () {
        for (let index = 0; index < this.$passwordTrigger.length; index++) {
            this.$passwordTrigger[index].addEventListener('click', () => {
                const type = this.$passwordContent[index].getAttribute('type') === 'password' ? 'text' : 'password';
                this.$passwordContent[index].setAttribute('type', type);

                if (type === 'text') {
                    this.$passwordTrigger[index].classList.toggle('icon-inactive');
                    this.$passwordTrigger[index].classList.remove('icon-active');
                } else {
                    this.$passwordTrigger[index].classList.toggle('icon-active');
                    this.$passwordTrigger[index].classList.remove('icon-inactive');
                }
            });
        }
    }

    checkNonRequiredFields () {
        for (let index = 0; index < this.$notRequiredFields.length; index++) {
            const inputValue = this.$notRequiredFields[index].value;

            if (inputValue !== '') {
                this.$notRequiredFields[index].parentElement.classList.add('is--filled');
            } else {
                this.$notRequiredFields[index].parentElement.classList.remove('is--filled');
            }

            this.$notRequiredFields[index].addEventListener('focus', () => {
                this.$notRequiredFields[index].parentElement.classList.add('is--filled');
            });
        }
    }

    checkRequiredFields () {
        let counter = 0;
        for (let index = 0; index < this.$fields.length; index++) {
            const inputValue = this.$fields[index].value;

            if (inputValue !== '') {
                counter++;
                this.$fields[index].parentElement.classList.add('is--filled');
            } else {
                this.$fields[index].parentElement.classList.remove('is--filled');
            }

            this.$fields[index].addEventListener('focus', () => {
                this.$fields[index].parentElement.classList.add('is--filled');
            });
        }

        if (this.$emailInput && this.$postcodeInput && this.$paymentIbanSepa) {
            if (((this.$formSubmitButton != null && this.validateEmail(this.$emailInput) === true && this.validatePostcode(this.$postcodeInput) === true && this.$paymentIbanSepa.checked && this.$paymentIbanSepa.classList.contains('required')) || (this.$formSubmitButton != null && this.validateEmail(this.$emailInput) === true && this.validatePostcode(this.$postcodeInput) === true && this.$paymentCreditcard.checked)) || (this.$formSubmitButton != null && this.validateEmail(this.$emailInput) === true && this.validatePostcode(this.$postcodeInput) === true && this.$paymentPayPal.checked)) {
                if (this.$paymentCreditcard.checked || this.$paymentPayPal.checked) {
                    counter++;
                    this.$formSubmitButton.disabled = counter !== this.$fields.length;
                } else {
                    /* CHANNELIZER FORM: fix disabling submitbutton when sepa-checkbox is changed */
                    if (this.isChannelizerForm) {
                        return false;
                    }

                    this.$formSubmitButton.disabled = counter !== this.$fields.length;
                }
            } else {
                /* CHANNELIZER FORM */
                if (this.isChannelizerForm) {
                    this.handleChannelizerForm();
                } else {
                    this.$formSubmitButton.disabled = true;
                }
            }
        } else {
            if (this.$formSubmitButton != null) {
                this.$formSubmitButton.disabled = counter !== this.$fields.length;
            }
        }
    }

    setEvents () {
        for (let index = 0; index < this.$fields.length; index++) {
            this.$fields[index].addEventListener('keyup', () => {
                this.checkRequiredFields();
                this.checkNonRequiredFields();
            });

            this.$fields[index].addEventListener('blur', () => {
                this.checkRequiredFields();
                this.checkNonRequiredFields();
            });

            this.$fields[index].addEventListener('change', () => {
                this.checkRequiredFields();
                this.checkNonRequiredFields();
            });

            this.$form.addEventListener('change', () => {
                this.checkRequiredFields();
                this.checkNonRequiredFields();
            });
        }

        if (document.documentElement.classList.contains('chrome')) {
            this.checkAutofill();
            this.checkRequiredFields();
            this.checkNonRequiredFields();
        }

        // if (this.$ibanInput) {
        //     this.$ibanInput.addEventListener('keyup', () => {
        //         this.$ibanInput.value = this.$ibanInput.value.toUpperCase();
        //     });
        //
        //     this.$ibanInput.addEventListener('blur', () => {
        //         this.$ibanInput.value = this.$ibanInput.value.toUpperCase();
        //     });
        // }

        if (this.$emailInput) {
            this.$emailInput.addEventListener('keyup', () => {
                this.validateEmail(this.$emailInput);
            });
        }

        if (this.$postcodeInput) {
            this.$postcodeInput.addEventListener('keyup', () => {
                this.validatePostcode(this.$postcodeInput);
            });
        }

        if (this.$formBirthDate) {
            this.$formBirthDate.addEventListener('input', () => {
                if (this.$formBirthDate.value.length === 0) {
                    this.$formBirthDate.parentElement.classList.remove('is--filled');
                    this.litepicker.clearSelection();
                }
            });
        }
    }

    checkAutofill () {
        const $emails = document.querySelectorAll('input[type="email"]:-webkit-autofill');
        const $password = document.querySelectorAll('input[type="password"]:-webkit-autofill');

        if ($emails.length > 0) {
            for (let emailIndex = 0; emailIndex < $emails.length; emailIndex++) {
                $emails[emailIndex].parentElement.classList.add('is--filled');
            }
        }

        if ($password.length > 0) {
            for (let pwIndex = 0; pwIndex < $password.length; pwIndex++) {
                $password[pwIndex].parentElement.classList.add('is--filled');
            }
        }
    }

    validatePostcode (input) {
        const validPostcode = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;
        return !!input.value.match(validPostcode);
    }

    validateEmail (input) {
        const validEmail = /^[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*([+-]\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return !!input.value.match(validEmail);
    }

    handleChannelizerForm () {
        const $channelizerForm = document.getElementById('channelizer__form');
        const $$formFields = $channelizerForm.querySelectorAll('[required]');
        const $radioIBAN = document.getElementById('registration_paymentMethod_0');

        /* enable submit button */
        this.$formSubmitButton.disabled = false;

        /* add eventlistner only on first submit */
        if (this.$firstLoad) {
            this.$formSubmitButton.addEventListener('click', (e) => {
                const $$emptyFields = [];

                /* get all required fields */
                for (let i = 0; i < $$formFields.length; i++) {
                    const $formField = $$formFields[i];

                    if ($formField.id === 'registration_iban' && !$radioIBAN.checked) {
                        continue;
                    }

                    /* remove all error notification */
                    $formField.parentElement.classList.remove('has--error');

                    /* if field has no value push to error-handling */
                    if ($formField.value === '') {
                        $$emptyFields.push($formField);
                    }

                    if ($formField.id === 'registration_sepaConfirmed' && !$formField.checked) {
                        $$emptyFields.push($formField);
                    }
                }

                console.log($$emptyFields);

                /* fire form if all required fields have a value */
                if ($$emptyFields.length === 0) {
                    return false;
                } else {
                    /* if not, handle errors  */
                    e.preventDefault();
                    for (let i = 0; i < $$emptyFields.length; i++) {
                        $$emptyFields[i].parentElement.classList.add('has--error');
                    }

                    /* scroll to first field with no value */
                    window.scrollTo({
                        top: $$emptyFields[0].getBoundingClientRect().top + window.scrollY - 180,
                        behavior: 'smooth'
                    });
                }

                /* first submit fired */
                this.$firstLoad = false;
            });
        }
    }
}

export { Form };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $form = $context.querySelectorAll('[data-form="root"]');

    if ($form.length > 0) {
        for (let i = 0; i < $form.length; i++) {
            const $newForm = new Form($form[i]);
            $newForm.initialize();
        }
    }
});
